/* Contact Container */
 
  /* Title */
  .contact-title {
    text-align: center;
 
    font-weight: 600;
    margin-bottom: 40px;
  
  }
  
 
  /* Info Section */
  .contact-info {
    width:calc(100% - 750px);
   float: left; 
  }
  
  .info-box {
    background: var(--layout_background);
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    transition: 0.3s;
margin: 10px 0px;
  }
  .info-box b ,p {
font-size: var(--font_size_title);
margin-right: 10px;
  }
  
  .info-box:hover {
    background: var(--theme_lite_color);
    transform: translateY(-5px);
  }
  
  .icon {
    font-size: 24px;
    margin-right: 15px;
    color: var(--icon_color);
  }
  
  h3 {
    margin: 0;
    font-size: 20px;
  }
  
  /* Form Section */
  .contact-form { 
 float: right;
    background: var(--layout_background);
    padding: 30px;
    border-radius: 10px;
    width:500px;
    
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .form-group {
    display: flex;

    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    text-align: left;
    margin-bottom: 8px;
  }
  
  input, textarea {
    padding: 10px;
    border: 1px solid var(--border_color);
    border-radius: 5px;
    transition: 0.3s;
    font-size: 14px;
    width:calc(100% - 20px);
  }
  
  input:focus, textarea:focus {
    outline: none;
    border-color: var(--theme_color);
    box-shadow: 0 0 5px var(--theme_lite_color);
  }
  
  .submit-btn {
    padding: 12px 20px;
    background: var(--theme_color);
    
    border: none;
    cursor: pointer;
    transition: 0.3s;
    font-size: 16px;
    border-radius: 5px;
  }
  
  .submit-btn:hover {
 opacity: 0.9;
  }
  
  /* Map */
  .map-container iframe {
    width: 100%;
    height: 400px;
    border: 0;
    margin-top: 20px;
    border-radius: 10px;
  
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
      /* Info Section */
  .contact-info {
    width:calc(100% - 20px);
    margin:10px auto;
   float: none; 
  }
  .contact-form { 
    float: none;
    margin:10px auto;
       background: var(--layout_background);
       padding: 20px;
       border-radius: 10px;
       width:calc(100% - 40px);
     }
       /* Map */
  .map-container iframe {
    width:calc(100% - 10px);
    height: 400px;
    border: 0;
    margin:10px auto;
    border-radius: 10px;
  
  }
  }
  