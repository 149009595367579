:root{
  --theme_color:#F8BD02;
  --theme_lite_color:#FCF5EB;
  --theme_gree_lite_color:#dcf8f2;
  --theme_violet_lite_color:#dad7f3;
  --theme_app_header_Text_color:#000;
  --theme_app_header_color:#fff;
  --theme_app_footer_color:#111B21;
  --theme_app_footer_boder_color:#21333f;
  --theme_app_footer_text_color:#fff;
  --body_background:white;
  --layout_background:#edeff0;
  --footer_background:rgb(46, 55, 66);
  --theme_green_color:#01A461;
  --blue_color:#2244da;
  --body_color:black;
  --red_color:#df0b2f;
  --link_color:#363638;
  --text_color:#000000;
  --text_blue:#2244da;
  --text_color_info:#838485;
  --icon_color:#000000;
  --border_color:#c3c7c9;
  --font_size_bg:40px;
  --font_size_bgm:35px;
  --font_size_header:20px;
  --font_size_title:17px;
  --font_size_button:16px;
  --font_size_normal:14px;
  --font_size_info:10px;
  --icon-color: brightness(0) invert(0);
}
[data-theme="dark"]{
  --theme_color:#F8BD02;
  --theme_lite_color:#FCF5EB;
  --theme_app_header_Text_color:#000;
  --theme_app_header_color:#fff;
  --theme_app_footer_color:#111B21;
  --theme_app_footer_text_color:#fff;
  --body_background:white;
  --layout_background:#F9F9F9;
  --footer_background:rgb(46, 55, 66);
  --body_color:black;
  --link_color:rgb(8, 8, 167);
  --text_color:#000000;
  --icon_color:#000000;
  --border_color:#ccc;
  --font_size_title:16px;
  --font_size_normal:12px;
  --font_size_info:10px;
  --icon-color: brightness(0) invert(0);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text_color);
  font-size: var(--font_size_normal);
  line-break: var(--text_color);
  lighting-color: var(--text_color);
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media only screen and (max-width: 600px) {
  font-weight:400;
}
