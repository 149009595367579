.container_card {
    max-width: 600px;
    margin: auto;
    border-radius: 5px;
    padding: 0px 10px;  
    transform: translateY(20px);  /* Slightly shifted down */
    transition: opacity 1.2s ease, transform 1.2s ease; /* Smooth transition */
}

 
.user-card
{
max-width: 100%;
display: flow-root;
 
clear: both;
 
padding: 5px 5px;
}
.user-card .photo-div
{
    width: 100px;
    height: 100px;
    float: left;
    position: relative;
}

.phone-div
{
  margin:5px 0px;
 float: left;
    background-color: var(--layout_background);
    padding: 5px 15px;
    border-radius: 30px;
    display:flex;
    
}
.phone-div .phone-icon
{
   
    width: 20px;
    height: 20px;
    float: left;
    margin: 5px 5px;
}
.phone-div .phone-text
{
    line-height: 30px;
    float: right;
    font-size: var(--font_size_title);
 font-weight: 600;
 
}
.user-card .photo-div .photo-img {
    width: 100px;
    background-color: #fff;
    height: 100px;
    object-fit: cover;   /* Use 'cover' for a better fit in circular images */
    border-radius: 50%;   /* Use 50% for a perfect circle */
    border: 2px solid #fff; /* Optional: Add a subtle border for clarity */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Optional: Add a soft shadow for a modern look */
    transition: transform 0.3s ease; /* Smooth hover effect */
}
.user-card .photo-div .Verified
{
position: absolute;
width: 25px;
height: 25px;
color: #fff;
border-radius: 50%;
right: 0px;
bottom: 0px;
padding: 3px;
background-color: var(--theme_green_color);
}
/* Hover effect for a subtle scale animation */
.user-card .photo-div .photo-img:hover {
    transform: scale(1.05);
}
.user-card .name-div
{
float: left;
 
width: calc(100% - 150px); 
margin-left: 20px;
text-align: left;
display: flow-root;
padding: 10px 0px;
}
.user-card .name-div .name-text
{
    font-size: var(--font_size_title);
    font-weight: 600;
}
.user-card .name-div .status-text
{
    
    font-weight: 400;
    margin: 5px 0px;
}

.container_card .gender-div
{
     float: left;    
   
    display:flow-root;

}
.container_card .adds-icon
{
float: left;
}
.container_card .gender-text
{    line-height: 25px;
    float: right;
    font-size: var(--font_size_title);
 font-weight: 600;
}
.container_card .dob-div
{
     float: right;      
    display:flow-root;

}
 
.container_card .address-text
{    line-height: 25px;
     
    text-align: left;
   
 font-weight: 400;
}
 
.container_card .row_verifed
{     float: left;      
    display:flow-root;
    font-weight: 400;
    background-color: var(--layout_background);
    
    border-radius: 30px;
    padding: 5px 10px;
}
.container_card .row_fill
{     float: right;      
    display:flow-root;
    background-color: var(--layout_background);
    
    border-radius: 30px;
    padding: 5px 5px;
}
.container_card .row_verifed .verifed-text
{    line-height: 25px;
    float: right;
    font-size: var(--font_size_title);
 font-weight: 400;
}
.container_card .row_fill .dob-text
{    line-height: 25px;
    float: right;
    font-size: var(--font_size_title);
 font-weight: 400;
}
.container_card .row_fill .joined-text
{    line-height: 25px;
    float: right;
    font-size: var(--font_size_title);
 font-weight: 400;
}
.container_card .media-card
{ margin:20px 0px;
    border-top: solid 2px var(--border_color);
    display: flow-root;
    clear: both;
    padding: 5px 0px;
}
.container_card .media-card .media-link
{ margin: auto;
    cursor: pointer;
     width: 30px;
     height: 30px;
     background-color: #fff;    
    clear: both;
    padding: 0px 0px;
    margin: 10px 20px;
}
.container_card .media-card .media-link:focus{
    -webkit-animation: pulse 1s ease-out;
}
