 
 

/* Features Section Styles */

.features, .cash-usage, .cta {

    padding: 60px 0;

    text-align: center;

}

 


.features h2, .cash-usage h2, .cta h2 {

   

    margin-bottom: 20px;

}



.features p, .cash-usage p, .cta p {

  

    margin-bottom: 40px;

}



.feature-list {

    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    gap: 30px;

    margin-bottom: 40px;

}



.feature-item {

    background-color: #f0f0f0;

    padding: 20px;

    border-radius: 8px;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}



.feature-item h3 {

    font-size: var(--font_size_title);

    margin-bottom: 10px;

}



.feature-item p {

    font-size: var(--font_size_title);

}



.feature-item ul {

    list-style-type: disc;

    margin: 0;

    padding-left: 20px;

    text-align: left;

}



.feature-item li {

  

    margin-bottom: 10px;

}



/* Cash Usage Section Styles */

.cash-usage ul {

    list-style-type: disc;

    text-align: left;

    margin: 0 auto;

    max-width: 600px;

    padding: 0;

}



.cash-usage li {

 
    margin-bottom: 10px;

    padding-left: 20px;

}

 

.container {

            max-width: 600px;

            width: 90%;

            background-color: #ffffff;

            border-radius: 12px;

            padding: 20px;

           

            text-align: center;

        }



      

.title_body

{

    max-width: 100%;

    display:flow-root;

}

.title_body .download_body

{ text-align: left;

float: left;

width:calc(100% - 55%) ;



}

.comments_body

{

    float: right;

    min-height: 400px;

    width:calc(100% - 50%) ;

    background-image: url(../assets/management_services.jpg);

            background-repeat:no-repeat;

            background-position:center;

             padding-top: 100px;

            background-size: 400px;

            position: relative;

}

.comments_body .management_title

{

position: absolute;

 left: 0px;

 font-size:16px;

bottom:-20px ;

text-align: left;

 font-weight: 400;

 color: #333;

padding: 5px;

border-radius: 10px;

max-width: 80%;

font-family: 'Roboto', sans-serif;   

    line-height: 1.2;    

     

}

.title_body .download_body a

{

color: #000;

}

        .download_body .title {

            font-size: var(--font_size_bg); 

            font-weight:600;

            margin-bottom: 10px;
 

        }



        .download_body .description {

            font-size: var(--font_size_title); 

            text-align: left;

            line-height: 1.6;

            margin-bottom: 5px;

            color: #000;

        }



        .download_body .download_div {

            clear: both;

           background-color: #eef9ff;

           border-radius: 5px;

           padding: 20px 15px;

            text-align: left;

            line-height: 1.6;

            margin-bottom: 20px;

            color: #000;

        }

        .download_body .download_div .app_ext

        {

            font-size: large;

            font-weight: 600;

        }

        .download_body .download_div .play_store_google_logo

        {

            width: 150px;

        height: 60px;

        margin-top: 20px;

            background-image: url(../assets/play_store_google_logo.png);

            background-repeat:no-repeat;

            background-position: center;

            cursor: pointer;

            background-size: 150px

        }

        .otherdownload-features

        {

            max-width: 100%;

            min-height: 300px;

          margin-top: 50px;

          text-align: left;

        }

        .otherdownload-features .slide

        {  background-color: #fcf6e4;

            max-width: 100%;

            padding: 10px;

            display: flow-root; 

            border-radius: 10px;

        }

        .otherdownload-features .slide .title_div

{ text-align: left;

float: left; 

width:calc(100% - 40%) ;

padding: 20px;

}

.otherdownload-features .slide .title_div .title {

            font-size:50px;

           line-height: 50px;

            font-weight: normal;

            margin-bottom: 10px;

            color: #000;

        }

        .otherdownload-features .slide .title_div .description {

            font-size: 16px;

            text-align: left;

            line-height: 1.6;

            margin-bottom: 5px;

            color: #000;

        }

        .otherdownload-features .slide .option_div

        {

            text-align: left;

float: right;

width:calc(100% - 60%) ;

background-color: #fff;

padding: 20px 15px;

min-height:250px;

border-radius: 10px;

        }

        .otherdownload-features .slide .option_div .app_ext

        {

            font-size: large;

            font-weight: 600;

        }

        .otherdownload-features .slide .option_div .btn_btn

        {

 



        text-decoration: none;
clear: both;
    margin-top: 50px;

 width: 200px;

 object-fit: contain;

 text-align: center;

  line-height: 28px;

  padding: 15px 25px;

 font-size: 16px;

 cursor: pointer;

 font-weight: 500;

  color: #333; 

       background-image: linear-gradient(30deg, #FCCA01, #FCCA01);

       border-radius: 25px;

       background-size: 100% auto;

       font-family: inherit;
 
       outline: none; 

  }

  .btn_btn:hover

  {  

    background-position: right center;

       background-size: 200% auto;

       -webkit-animation: pulse 2s infinite;

       animation: pulse512 1.5s infinite;

  }

        .security-features {



            margin: 50px 0px;

        }



        .security-title {

            font-size: 20px;

            font-weight: bold;

            color: #000;

            margin-bottom: 10px;

        }



        .feature-list {

            text-align: left;

            font-size: 12px;

            color: #333;

            line-height: 1.8;

            list-style-type: disc;

            padding-left: 20px;

        }



        .cta-button {

            display: inline-block;

            padding: 12px 24px;

            background-color: #F8BC01;

            color: #000;

            border: none;

            border-radius: 6px;

            text-decoration: none;

            font-size: 16px;

            transition: background-color 0.3s ease;

            cursor: pointer;

        }



        .cta-button:hover {

            background-color: #0071D9;

            color: #ffffff;

        }

/* Responsive Design */

@media (max-width: 768px) {

   



    .features h2, .cash-usage h2, .cta h2 {

        font-size: 1.75rem;

    }



    .feature-item h3 {

        font-size: 1.25rem;

    }

    

.title_body

{

    max-width: 100%;

    display:flow-root;

}

.title_body .download_body

{ text-align: center;

float: none;

width:calc(100% - 0%) ;



}

.comments_body

{

    float: none;

    min-height: 300px;

    width:calc(100% - 0%) ;

    background-image: url(../assets/management_services.jpg);
            background-repeat:no-repeat;

            background-position:center;

             padding-top: 100px;

            background-size: 300px;

            position: relative;

}

.comments_body .management_title

{

position: absolute;

 left: 0px;

 font-size:14px;

bottom:-20px ;

text-align: left;

 font-weight: 400;

 color: #333;

padding: 5px;

border-radius: 10px;

max-width: 100%;

font-family: 'Roboto', sans-serif;   

    line-height: 1.2;    

     

}

 
        .download_body .title {

            font-size: var(--font_size_bg);

           

            font-weight: 600;

            margin-bottom: 5px;

            color: #000;

        }



        

        .download_body .download_div {

            clear: both;

           background-color: #eef9ff;

           border-radius: 5px;

           padding:10px 10px;

            text-align: left;

            line-height: 1.6;

            margin-bottom: 10px;

            color: #000;

        }

        .download_body .download_div .app_ext

        {

            font-size: large;

            font-weight: 600;

        }

        .download_body .download_div .play_store_google_logo

        {

            width: 150px;

        height: 60px;

        margin-top: 20px;

            background-image: url(../assets/play_store_google_logo.png);

            background-repeat:no-repeat;

            background-position: center;

            cursor: pointer;

            background-size: 150px

        }



        

        .otherdownload-features

        {

            max-width: 100%;

            min-height: 300px;

          margin-top: 50px;

          text-align: left;

        }

        .otherdownload-features .slide

        {  background-color: #fcf6e4;

            max-width: 100%;

            padding: 10px;

            display: flow-root;

             

            border-radius: 10px;

        }

        .otherdownload-features .slide .title_div

{ text-align: left;

float: none; 

width:calc(100% - 0%) ;

padding: 20px;

}

.otherdownload-features .slide .title_div .title {

            font-size:30px;

           line-height: 30px;

            font-weight: normal;

            margin-bottom: 10px;

            color: #000;

        }

        .otherdownload-features .slide .title_div .description {

            font-size: 16px;

            text-align: left;

            line-height: 1.6;

            margin-bottom: 5px;

            color: #000;

        }

        .otherdownload-features .slide .option_div

        {

            text-align: left;

float: none;

clear: both;

width:calc(100% - 0%) ;

background-color: #fff;

padding: 20px 15px;

min-height:250px;

border-radius: 10px;

        }

        .otherdownload-features .slide .option_div .app_ext

        {

            font-size: large;

            font-weight: 500;

        }
 
}

