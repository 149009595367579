/* General Body Styling */
.show-case-alert-body {
  padding: 10px 20px;
  background: var(--theme_gree_lite_color);
  margin: 0;
  display: flow-root;
}

/* Showcase Section */
.show-case-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  text-align: left;
}

/* Left Image Section */
.left-div {
  flex: 1;
  max-width: 20%;
  text-align: left;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
 
}

.image:hover {
  transform: scale(1.05);
}

/* Right Content Section */
.right-div {
  flex: 1;
  max-width: 55%;
}

.heading {
  font-size:var(--font_size_title);
  margin-bottom: 20px;
}

.paragraph {
  font-size:var(--font_size_title);
  line-height: 1.8;
}

.list {
  list-style-type: disc;
  margin-left: 20px;
}

.list li {
  font-size:var(--font_size_title);
  line-height: 1.6;
}

/* Title and Description */
.title-div {
  text-align: center;
  margin-bottom: 40px;
}

.title-text {
  font-size: 36px;
  font-weight: bold;
 
}

.desc-text {
  font-size: 18px;
  line-height: 1.5;
 
}

/* Mobile Responsiveness */
@media (max-width: 1024px) {
  .show-case-alert {
    flex-direction: column;
    gap: 30px;
  }

  .left-div, .right-div {
    max-width: 100%;
    text-align: center;
  }

  .image {
    width: 90%;
    margin: 0 auto;
  }

  .title-text {
    font-size: 30px;
  }

  .desc-text, .paragraph, .list li {
    font-size: 16px;
  }

  .heading {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .title-text {
    font-size: 28px;
  }

  .desc-text, .paragraph, .list li {
    font-size: 14px;
  }

  .heading {
    font-size: 22px;
  }

  .list {
    margin-left: 10px;
  }
}

@media (max-width: 480px) {
  .title-text {
    font-size: 24px;
  }

  .desc-text, .paragraph, .list li {
    font-size: 14px;
  }

  .heading {
    font-size: 20px;
  }

  .list {
    margin-left: 5px;
  }

  .image {
    width: 100%;
  }
}
