.mobile-menu-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--layout_background);
    z-index: 999;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobile-menu-container.open {
    transform: translateX(0);
}

.menu-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--icon-color);
}

.menu {
    list-style: none;
    padding: 0;
    text-align: center;
}

.menu ul {
    padding: 0;
    list-style: none;
}

.menu li {
    margin: 20px 0;
}

.menu a {
    text-decoration: none;
    color: var(--text_color);
    font-size: 22px;
    transition: color 0.3s;
}

.menu a:hover {
    color: var(--theme_color);
}
