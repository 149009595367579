.switch {
     
    height: 24px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 2px;
    cursor: pointer;
    transition: background 0.3s;
margin: 4px 2px;
  }
  
  .switch-handle {
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    transition: 0.3s;
  }
  
  .switch.on .switch-handle {
    transform: translateX(45px);
  }
  
  .switch.off .switch-handle {
    transform: translateX(0);
  }
  
  .switch-label {
    color: white;
    font-size: var(--font_size_normal);
    font-weight: 400;
    margin: 0 8px;
     
  }
  