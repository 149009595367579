/* Button Styling */
.custom-button {
    display: inline-flex;
    align-items: center;         /* Center-align image and text vertically */
    justify-content: center;     /* Center-align horizontally */
    gap: 5px;                   /* Space between text and image */
    
    background: var(--theme_blue_color, var(--theme_color));
    color: var(--text_color);
    text-decoration: none;
    padding: 0px 5px 0px 20px; 
    margin: 0px 5px;
    font-size: var(--font_size_button);
    border-radius: 30px;
    height: 40px;
    transition: transform 0.3s ease;
    cursor: pointer;
    line-height: 24px;
  }
  
  /* Center-Aligned Text */
  .button-text {
    white-space: nowrap;        /* Prevents text wrapping */
    text-align: center;         /* Center-align text */
  }
  
  /* Center-Aligned Image */
  .button-icon {
    width: 22px; 
    height: 22px;
    transition: transform 0.3s ease;
  }
  
  /* Hover Effect */
  .custom-button:hover {
 
    -webkit-animation: pulse 1s ease-out;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .custom-button {
      display: inline-flex;
      align-items: center;   
      justify-content: center;    
      gap: 2px;                 
      
      background: var(--body_background, var(--theme_color));
      color: var(--text_color);
      border: solid 1px var(--border_color);
      text-decoration: none;
      padding: 2px 2px 2px 10px; 
      margin: 1px 5px;
      font-size: var(--font_size_button);
      border-radius: 30px;
   
      height: 35px;
      transition: transform 0.3s ease;
      cursor: pointer;
    }
    
    /* Center-Aligned Text */
    .custom-button .button-text {
      white-space: nowrap;        /* Prevents text wrapping */
      text-align: center;         /* Center-align text */
      font-weight: 400;
    }
    
    /* Center-Aligned Image */
    .custom-button .button-icon {
      width: 20px; 
      height: 20px;
      display: none;
      transition: transform 0.3s ease;
    }
    
    /* Hover Effect */
    .custom-button .custom-button:hover {
   
      -webkit-animation: pulse 1s ease-out;
    }
  }
  
  @media (max-width: 480px) {
    .custom-button .custom-button {
      padding: 4px 15px;
      font-size: 12px;
      height: 30px;
      gap: 5px;
    }
  
    .custom-button .button-icon {
      width: 18px;
      height: 18px;
    }
  }
  