.App {
  text-align: center;
}
.app_header_height{
  height:70px;
  clear: both;
}
.container_size
{
  max-width: 1300px;
  margin: auto;
  margin: 0 auto;
  padding: 20px;
  display: flow-root;
  clear: both;
}
table{
  width: 100%;
}
.container_roots
{padding: 0px;
  min-height:calc(100vh - 130px);
}
.icon {

  color: var(--icon_color);
  height: 24px;
  width: 24px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0px;
}

.tab {
  display:flow-root;
  align-items: center;
  gap: 2px;
  padding: 10px 2px;
  background-color: transparent;
  color: var(--text_color_info);
  border: 3px solid transparent;
  cursor: pointer;
  transition: 0.3s;
  outline: none;
  line-height: 14px;
  
  font-size: var(--font_size_button);
}

.tab:hover {
  background-color: transparent;
}

.tab.active {
  background-color: transparent;
  color: var(--text_color);
  border: 3px solid transparent;
  border-bottom: solid 2px var(--text_color);
}

.tab-icon {
  
  margin: 6px 5px 6px 1px;
  height: 30px;
  width: 30px;
}
.tab.active.tab-icon 
{
  color: var(--text_color);
}
.tab-content {
  padding: 20px;
  
  min-height: 150px;
  text-align: left;
}

@keyframes pulse {

  0% {

   box-shadow: 0 0 0 0 var(--theme_app_footer_color);

  }

 

  70% {

   box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);

  }

 

  100% {

   box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);

  }

 }
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
